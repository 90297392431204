<!--
 * @Author: sqk
 * @Date: 2020-08-19 10:51:00
 * @LastEditTime: 2021-01-22 15:21:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\Pie.vue
-->

<template>
  <div ref="pie" style="width:100%;height:100%"></div>
</template>

<script>
// import util from "./util";
export default {
  name: "pie",
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    data: function() {
      this.initEcharts();
    }
  },
  mounted() {
    this.initEcharts();
    // window.addEventListener("resize", this.initEcharts);
  },
  methods: {
    // 初始化echarts图表
    initEcharts() {
      let pie = this.$echarts.init(this.$refs.pie);
      // let data = this.data;

      var timeOut = setTimeout(() => {
        let cgSumCount = 0,yjSumCount = 0;
        for (const item of this.data) {
          cgSumCount += Number(item.currMonthCgOrderCount);
          yjSumCount += Number(item.currMonthYjOrderCount);
        }
        let countArr = [yjSumCount,cgSumCount]
        let option = this.chartInt(countArr);
        pie.setOption(option);
        clearTimeout(timeOut);
      }, 1);
    },
    // 配置options
    chartInt(countArr) {
      //职称结构取数
      let legend = ["应急订单台数及占比", "常规订单台数及占比"];
      // let sum = countArr[0] + countArr[1]
      let sumCount = 0;
      for (const item of countArr) {
        sumCount += item
      };
      let data = [];
      for (var i = 0; i < legend.length; i++) {

        data.push({
          name: legend[i],
          value: countArr[i],
          proportion:countArr[i] ? (countArr[i]*100/sumCount).toFixed(2) + '%' : '0.00%'
        });
      }

      //职称结构图表
      var colorList = ["#ff8f3c","#73A0FA", ];
      let option = {
        tooltip: {
          trigger: "item",
          // formatter: "{b} : {c} | ({d}%)",
          formatter: function(params) {
            // var str = "";
            // for (var i = 1; i < params.length; i++) {
            //     if (params[i].seriesName !== "") {
            //         str +=
            //             params[i].seriesName +
            //             "：" +
            //             params[i].data.count + ' | 占比：' + params[i].data.value + '%'
            //             + "<br/>";
            //     }
            // }
            if(params.name){
              return params.name + ':' + params.value + ' | ' + params.data.proportion;
            }
            
        },
          textStyle: {
            fontSize: 16
          },

        },
        // grid: {
        //   left: "10%",
        //   right: "5%",
        //   bottom: "8%",
        //   containLabel: true
        // },
        legend: {
          orient: "horizontal", // 'vertical'
          x: "right", // 'center' | 'left' | {number},
          y: "8", // 'center' | 'bottom' | {number}
          // backgroundColor: '#fff',
          // borderColor: 'rgba(178,34,34,0.8)',
          // borderWidth: 4,
          padding: [0, 24, 0, 0], // [5, 10, 15, 20]
          itemGap: 16,
          // textStyle: {
          //     color: '#777'
          // },
          icon: "circle",
          itemWidth:10,
          itemHeight:10,
          selectedMode:false,//取消图例的点击事件
          data: legend,
          formatter: function(name) {
            let arr = ["{a|" + name.slice(0, 2) + "}"];
            return arr.join("\n");
            // let target;
            // for(let i=0;i<getsjjg.length;i++){
            //     if(getsjjg[i].name===name){
            //         target=data[i].value
            //     }
            // }
            // let arr=["{a|"+target+"}","{b|"+name+"}"]
            // return arr.join("\n")
          },
          textStyle: {
            rich: {
              a: {
                fontSize: 12,
                padding: 0,
                color: "#262626"
              },
            }
          }
        },
        backgroundColor: "#fff",
        series: [
          {
            itemStyle: {
              normal: {
                color: "#F7F7F7"
              },
              emphasis:{color:'#F7F7F7'}
            },
            clickable:false,//取消点击事件
            type: "pie",
            hoverAnimation: false,
            center: ["50%", "50%"],
            radius: ["50%", "56%"],
            label: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: 0
              }
            ],
            // z: 3
          },
          {
            // z: 5,
            hoverAnimation: false,
            itemStyle: {
              // normal: {
              //     color: function(params) {
              //         return colorList[params.dataIndex]
              //     },
              //     borderColor: '#fff',
              //     borderWidth: 3
              // }
              normal: {
                color: function(params) {
                  return colorList[params.dataIndex];
                },
                // borderWidth: 6,
                borderWidth: 0,
                borderColor: "#ffffff"
              },
              emphasis: {
                borderWidth: 0,
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            },
            type: "pie",
            //clockWise: false ,
            //roseType: 'radius',
            radius: ["0%", "50%"],
            center: ["50%", "50%"],

            // labelLine: {
            //     normal: {
            //         lineStyle: {
            //             color: '#34569D'
            //         }
            //     }
            // },
            // label: {
            //     normal: {
            //         formatter: params => {
            //             return (
            //                 '{name|' + params.name + '}{percent|' + params.percent.toFixed(0) + '%}'
            //             );
            //         },
            //             position: 'outside',
            //                     rich: {
            //         hr: {
            //             backgroundColor: 't',
            //             borderRadius: 100,
            //             width: 0,
            //             height: 10,
            //             padding: [3, 3, 0, -16],
            //             shadowColor: '#1c1b3a',
            //             shadowBlur: 1,
            //             shadowOffsetX: '0',
            //             shadowOffsetY: '2',
            //         },
            //         a: {
            //             padding: [-35, 15, -20, 5],
            //         }
            //     },
            //         padding: [0, -5, 0, -5],
            //     }
            // },
            label: {
              show: true,
              // normal: {
              //     color:'#000',
              // },
              // position: 'outside',

              // width:'10',

              normal: {
                // color: '#000',
                formatter: function(val) {
                  //让series 中的文字进行换行
                  let title = ''
                  // let sumCount = 0;
                  // for (const item of countArr) {
                  //   sumCount += item
                  // };
                  // if(val.name.length > 8){
                  //   title = val.name.slice(0,4)+'\n'+val.name.slice(4,9) + '\n' + val.value + ' | ' + (val.value ? (val.value*100/sumCount).toFixed(2) : '0.00') + '%';
                  // }
                  if(val.name.length > 5){
                    title = val.name.slice(0,7)+'\n'+val.name.slice(7) + ' ' + val.value + ' | ' + (val.value ? (val.value*100/sumCount).toFixed(2) : '0.00') + '%';
                  }else{
                    title = val.name + ' ' + (val.value ? (val.value*100/sumCount).toFixed(2) : '0.00') + '%';
                  }
                  // return name + val.value;
                  // return "\n{hr|}\n\n\n\n{a|"+title+"}";
                  return "\n\n\n\n{hr|}\n\n{a|"+title+"}";
                  // return "{a|{b}：{d}%}\n{hr|}\n\n\n";
                },
                // formatter: "{a|{b}：{d}%}\n{hr|}\n\n\n",
                rich: {
                  hr: {
                    color: "t",
                    backgroundColor: "t",
                    borderRadius: 2,
                    width: 0,
                    height: 4,
                    padding: [0, 0, 0, -4],
                    // align:'top',
                    // y:50
                    // margin: [0, -100, 0, 0]
                    // padding: [3, 3, 0, -16],
                    // shadowColor: '#1c1b3a',
                    // shadowBlur: 1,
                    // shadowOffsetX: '0',
                    // shadowOffsetY: '2',
                  },
                  a: {
                    lineHeight:18,
                    // color:'#000',
                    // color:'#B0CEFC',
                    align:'left',
                        //                 textStyle:{
                        //                   align:'center',
                    		// 	fontSize:12,
                    		// 	color:'#B0CEFC'
                    		// },
                    // padding: [-35, -50, 10, 0]
                    padding: [20, -50,10,-40]
                    // padding: [0, -40]
                  }
                }
              },
            },
            labelLine: {
              normal: {
                length: 26,
                length2: 34,
                lineStyle: {
                  width: 1
                }
              }
            },
            data: data
          }
        ]
      };
      return option;
    }
  }
};
</script>

