<!--
 * @Author: your name
 * @Date: 2021-01-06 17:16:24
 * @LastEditTime: 2021-01-22 15:53:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\statistics\components\QueryPop.vue
-->
<template>
<!--  :getPopupContainer="triggerNode =>  triggerNode.parentNode|| document.body"加上这个会导致第一次弹出位置不对-->
  <a-popover :title="title" trigger="hover" overlayClassName="popover_pop" >
    <template slot="content">
      {{content}}
    </template>
      <i class="iconfont icon-tijiwenti-pc popover_pop_toast"></i>
  </a-popover>
</template>
<script>
export default {
  // 示例
  // <volume-pop></volume-pop>u
  name: "queryPop",
  data() {
    return {
      // title:'物流费用规则',
      // list:'起运量标准：单电视商家5台，白电及全品类商家3立方米'
    };
  },
  props: {
    title: {
      type: String,
      default: '提示'
    },
    content:{
      type: String,
      default: '选定月份范围的总和订单台数占比统计饼图'
    }
  },
};
</script>

<style lang="less">
  // 物流规则
  .popover_pop{
    .ant-popover-inner{
      padding:0 16px;
    }
    .ant-popover-title{
      padding:12px 0px 12px 0px;
      box-sizing: border-box;
      min-width:150px;
      font-size: 16px;
    }
    .ant-popover-inner-content{
      padding:12px 0px 12px 0px;
      font-size: 14px;
    }
  }
  .popover_pop_toast {
      color:#00aaa6;
      cursor: pointer;
      font-size: 20px;
    }
  
  
</style>
