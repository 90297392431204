<!--
 * @Author: sqk
 * @Date: 2020-08-19 10:51:00
 * @LastEditTime: 2021-06-11 17:11:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\Pie.vue
-->

<template>
  <div :id="data[0].orgId +  data[0].matklId" ref="bar" style="width:100%;height:100%"></div>
</template>

<script>
// import util from "./util";
export default {
  name: "bar",
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    chartItemArr: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    data: function() {
      // for (const item of this.chartItemArr) {
      //   if(item.orgId + item.matklId == this.data[0].orgId + this.data[0].matklId){
      //     return
      //   }
      // }
      this.initEcharts();
    },
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    // 初始化echarts图表
    initEcharts() {
      let bar = this.$echarts.init(this.$refs.bar);
      // let data = this.data;

      let timeOut = setTimeout(() => {
        let cgData = this.data.map(item => {
          return {
            name: item["yearMonth"],
            value:
              item["monthChRatio"] != "0%"
                ? Number(
                    item["monthChRatio"].slice(
                      0,
                      item["monthChRatio"].length - 1
                    )
                  )
                : "0",
            count: Number(item["currMonthCgOrderCount"]).toFixed(0),
            orgId: item["orgId"], 
            matklId: item["matklId"] 
          };
        });
        let yjData = this.data.map(item => {
          return {
            name: item["yearMonth"],
            value:
              item["monthYjRatio"] != "0%"
                ? Number(
                    item["monthYjRatio"].slice(
                      0,
                      item["monthYjRatio"].length - 1
                    )
                  )
                : "0",
            count: Number(item["currMonthYjOrderCount"]).toFixed(0),
            orgId: item["orgId"], //指标id
            matklId: item["matklId"] //地区id
          };
        });

        let areaArr = [];
        //+10 为美观 溢出柱图上方
        for (const item of this.data) {
          let sum =
            10 +
            Number(item.monthChRatio.slice(0, item.monthChRatio.length - 1)) +
            Number(item.monthYjRatio.slice(0, item.monthYjRatio.length - 1));
          areaArr.push(sum);
        }
        //月份数组
        let monthArr = this.$util.getArr(this.data, "yearMonth");
        //月份常规订单占比常规数据数组
        let cgValArr = this.$util.getArr(this.data, "currMonthCgOrderCount");
        //月份应急订单数量数据数组
        let yjValArr = this.$util.getArr(this.data, "currMonthYjOrderCount");
        //合并
        let dataArr = [...[cgData], ...[yjData]];

        let option = this.chartInt(monthArr, dataArr, areaArr);
        bar.setOption(option);
        // bar.resize();
        clearTimeout(timeOut);

        //滚动到新生成的月份图表位置
        $("body,html").animate(
          {
            scrollTop: $('#' + this.data[0].orgId +  this.data[0].matklId).offset().top - 80
          },
          500
        );
      }, 1);
    },
    // 配置options
    chartInt(monthArr, dataArr, areaArr) {
      let legend = ["常规订单台数", "应急订单台数"];
      //   var data = {
      //     area: ["2020-01", "2020-01", "2020-01", "2020-01", "2020-01"],
      //     legend: ["常规订单数", "应急"],
      //     data: [
      //       [200, 100, 300, 200, 200],
      //       [200, 100, 300, 200, 300]
      //     ]
      //   };
      //   var colors = ["#73A0FA", "#73DEB3"];
      let option = {
        backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          textStyle: {
            align: "left"
          },
          //   formatter: "{b} : {c}",
          formatter: function(params) {
            let str = "";
            let newArr = params.reverse();
            for (var i = 0; i < newArr.length; i++) {
              // for (var i = params.length; i > 0; i--) {
              if (
                params[i].seriesName !== "" &&
                params[i].componentSubType == "bar"
              ) {
                str +=
                  params[i].seriesName +
                  "：" +
                  params[i].data.count +
                  " | 占比：" +
                  Number(params[i].data.value) +
                  "%" +
                  "<br/>";
              }
            }
            return str;
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        // title: {
        //     text: '大同市致贫原因分析',
        //     left: 19,
        //     top: 15,
        //     textStyle: {
        //         color: '#fff'
        //     }
        // },
        color: ["#73A0FA", "#73DEB3"],
        legend: {
          orient: "horizontal", // 'vertical'
          x: "right", // 'center' | 'left' | {number},
          // y: "top",
          y: "8",
          padding: [0, 24, 0, 0],
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 16,
          selectedMode: false, //取消图例的点击事件
          // padding: [5, 10],
          formatter: function(name) {
            return name.slice(0, 2);
          },
          textStyle: {
            fontSize: 12,
            color: "#262626",
            padding: [0, 0, 0, 0]
          },
          data: legend
        },
        grid: {
          // top:"16%",
          left: "4%",
          right: "3.6%",
          bottom: "8%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: "#262626",
            margin: 12
          },
          axisLine: {
            lineStyle: {
              color: "#eee"
            }
          },
          axisTick: {
            show: false
          },
          data: monthArr
        },
        yAxis: {
          type: "value",
          //   axisLabel: {
          //     color: "#262626"
          //   },
          min: 0,
          max: 100,
          axisLabel: {
            show: true,
            formatter(value) {
              //   if (this_.barPopObj.typeChartUnit == "%") {
              //     var res = this_.$util.getFloat(value * 100, 0) + "%";
              //     return res;
              //   } else {
              //     return value;
              //   }
              return value + "%";
              // //value当前值，index当前索引
              // return value.split(" ")[1];
            },
            textStyle: {
              color: "#262626"
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: "solid",
              color: "#eee"
            }
          }
        },
        series: [
          {
            type: "line",
            showAllSymbol: true,
            smooth: true,
            symbol: "circle",
            symbolSize: 0,
            lineStyle: {
              normal: {
                width: 0
              }
            },
            label: {
              show: false
              // position: 'top',
              // textStyle: {
              //     color: '#A582EA',
              // }
            },
            itemStyle: {
              color: "#fff",
              borderColor: "#A582EA",
              borderWidth: 2
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(115, 160, 250, 0.37)"
                    },
                    {
                      offset: 0.5,
                      color: "rgba(115, 160, 250, 0.17)"
                    },
                    {
                      offset: 1,
                      color: "rgba(105, 143, 253, 0.05)"
                    }
                  ],
                  false
                )
              }
            },
            //   data: [500, 300, 700, 500, 600], //data.values
            data: areaArr, //data.values
            z: -1
          }
        ]
      };
      for (var i = legend.length; i >= 0; i--) {
        // for (var i = 0; i < legend.length; i++) {
        option.series.push({
          name: legend[i],
          type: "bar",
          stack: "总量",
          barWidth: "45%",
          label: {
            show: false,
            // position: "insideRight"
            position: "inside",
            formatter: function(data) {
              return data.data.value + "%";
            }
          },
          data: dataArr[i]
        });
      }
      return option;
    }
  }
};
</script>

