<!--
 * @Author: sqk
 * @Date: 2020-08-19 10:51:00
 * @LastEditTime: 2021-06-11 18:16:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\Pie.vue
-->

<template>
  <div ref="collect" style="width:100%;height:100%"></div>
</template>

<script>
// import util from "./util";
export default {
  name: "bar",
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    data: function() {
      this.initEcharts();
    }
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    // 初始化echarts图表
    initEcharts() {
      let collect = this.$echarts.init(this.$refs.collect);
      // let data = this.data;

      let timeOut = setTimeout(() => {
        //X轴标题,常规数据，应急数据，常规占比数据，应急占比数据
        let collectData = {
          Xdata: [],
          cgData: [],
          yjData: [],
          cgZbData: [],
          yjZbData: []
        };

        // collectData.Xdata = [];
        // collectData.cgData = [];
        // collectData.yjData = [];
        // collectData.cgZbData = [];
        // collectData.yjZbData = [];

        for (const itemL1 of this.data) {
          // collectData.Xdata.push(itemL1.matklName + "\n\n" + itemL1.orgName);
          collectData.Xdata.push(itemL1.matklName + " | " + itemL1.orgName);
          let cgSumCount = 0,
            yjSumCount = 0;
          for (const itemL2 of itemL1.list) {
            cgSumCount += Number(itemL2.currMonthCgOrderCount);
            yjSumCount += Number(itemL2.currMonthYjOrderCount);
          }
          collectData.cgData.push(cgSumCount);
          collectData.yjData.push(yjSumCount);
          collectData.cgZbData.push(
            Number(cgSumCount + yjSumCount) > 0 ? 
            ((cgSumCount * 100) / (cgSumCount + yjSumCount)).toFixed(2) : 0
          );
          collectData.yjZbData.push(
            Number(cgSumCount + yjSumCount) > 0 ? 
            ((yjSumCount * 100) / (cgSumCount + yjSumCount)).toFixed(2) : 0
          );
        }
        let option = this.chartInt(collectData);
        collect.setOption(option);
        // bar.resize();
        clearTimeout(timeOut);
      }, 1);
    },
    // 配置options
    chartInt(collectData) {
      let option = {
        /*  grid: {
    top: "20%",
    bottom: "12%"
  },*/
        // backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          textStyle: {
            align: "left"
          },
          formatter(params) {
            let str = "";
            let newArr = params;

            str += params[0].name + "<br/>";
            for (var i = 0; i < newArr.length; i++) {
              // for (var i = params.length; i > 0; i--) {
              if (params[i].componentSubType == "bar") {
                str += params[i].seriesName + "：" + params[i].data + "<br/>";
              } else {
                str +=
                  params[i].seriesName + "：" + params[i].data + "%" + "<br/>";
              }
            }
            return str;
          },
          axisPointer: {
            type: "shadow",
            // label: {//文字着重
            //   show: true
            // }
          }
        },
        grid: {
          top: "20%",
          left: "4%",
          right: "3.6%",
          bottom: "6%",
          containLabel: true
        },
        legend: {
          orient: "horizontal", // 'vertical'
          x: "right", // 'center' | 'left' | {number},
          // y: "top",
          y: "30",
          padding: [0, 24, 0, 0],
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 16,
          selectedMode: false, //取消图例的点击事件
          // padding: [5, 10],
          // formatter: function(name) {
          //   return name.slice(0, 2);
          // },
          textStyle: {
            fontSize: 12,
            color: "#262626",
            padding: [0, 0, 0, 0]
          }
        },
        xAxis: {
          type: "category",
          // data: [
          //   "区域1\n\n青岛附近开了",
          //   "区域1\n\n青岛附近开了",
          //   "区域3",
          //   "区域4",
          //   "区域5",
          //   "区域6"
          // ],
          data: collectData.Xdata,
          axisLine: {
            show: false, //隐藏X轴轴线
            lineStyle: {
              color: "rgba(0,0,0,.45)",
              lineHeight: 26
            }
          },
          axisTick: {
            show: false //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#262626" //X轴文字颜色
              // fontSize: 16
            },
            interval:0,
            rotate:50
          }
          // splitArea: {
          //   show: true,
          //   areaStyle: {
          //     color: ["rgba(250,250,250,0.1)", "rgba(250,250,250,0)"]
          //   }
          // }
        },
        yAxis: [
          {
            type: "value",
            //   axisLabel: {
            //     color: "#262626"
            //   },
            //   min: 0,
            //     max: 100,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#262626"
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              lineStyle: {
                type: "solid",
                color: "#eee"
              }
            }
          },
          {
            type: "value",
            position: "right",
            //   axisLabel: {
            //     color: "#262626"
            //   },
            //   min: 0,
            //     max: 100,
            axisLabel: {
              show: true,
              formatter: "{value} %", //右侧Y轴文字显示
              textStyle: {
                color: "#262626"
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "solid",
                color: "#eee"
              }
            }
          }
        ],
        series: [
          {
            name: "常规订单台数",
            type: "bar",
            barWidth: "15%",
            itemStyle: {
              normal: {
                color: "#73a0fa"
                // "label": {
                //     "show": true,
                //     "textStyle": {
                //         "color": "#fff"
                //     },
                //     "position": "inside",
                //     formatter: function(p) {
                //         return p.value > 0 ? (p.value) : '';
                //     }
                // }
              }
            },
            // data: [4, 3, 2, 8, 3, 5]
            data: collectData.cgData
          },
          {
            name: "应急订单台数",
            type: "bar",
            barWidth: "15%",
            itemStyle: {
              normal: {
                color: "#ff8f3c"
                // "label": {
                //     "show": true,
                //     "textStyle": {
                //         "color": "#fff"
                //     },
                //     "position": "inside",
                //     formatter: function(p) {
                //         return p.value > 0 ? (p.value) : '';
                //     }
                // }
              }
            },
            // data: [3, 2, 3, 5, 4, 3]
            data: collectData.yjData
          },
          {
            name: "常规占比",
            type: "line",
            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            // symbol: "circle", //标记的图形为实心圆
            symbolSize: collectData.cgZbData.length > 1 ? 8 : 0, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: "#24d9c5",
              borderColor: "#24d9c5",
              width: 2,
              shadowColor: "#24d9c5",
              shadowBlur: 4
            },
            // lineStyle: {
            //   color: "#3D7EEB",
            //   width:2,
            //   shadowColor: "#3D7EEB",
            //   shadowBlur: 4
            // },
            // areaStyle: {
            //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            //     offset: 0,
            //     color: "rgba(61,126,235, 0.5)"
            //   },
            //     {
            //       offset: 1,
            //       color: "rgba(61,126,235, 0)"
            //     }
            //   ])
            // },
            // data: [4.2, 3.5, 2.9, 7.8, 5, 3]
            data: collectData.cgZbData
          },
          {
            name: "应急占比",
            type: "line",
            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            // symbol: "circle", //标记的图形为实心圆
            symbolSize: collectData.yjZbData.length > 1 ? 8 : 0, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: "#9d85Fe",
              borderColor: "#9d85Fe",
              width: 2,
              shadowColor: "#9d85Fe",
              shadowBlur: 4
            },
            // lineStyle: {
            //   color: "#3D7EEB",
            //   width:2,
            //   shadowColor: "#3D7EEB",
            //   shadowBlur: 4
            // },
            // areaStyle: {
            //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            //     offset: 0,
            //     color: "rgba(61,126,235, 0.5)"
            //   },
            //     {
            //       offset: 1,
            //       color: "rgba(61,126,235, 0)"
            //     }
            //   ])
            // },
            // data: [1, 3, 6, 1, 5, 3],
            data: collectData.yjZbData
          }
        ]
      };
      return option;
    }
  }
};
</script>

