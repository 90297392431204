/*
 * @Author: your name
 * @Date: 2020-12-24 15:37:13
 * @LastEditTime: 2021-05-25 15:02:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\statistics\index.js
 */
import { mapState } from "vuex";
//引入nav数据
import navUtil from "@/components/global/CustomizeNav/util";
// import util from "./components/util";
import moment from "moment";
//饼图组件
import pie from "./components/Pie";
//柱形图组件
import bar from "./components/Bar";
//汇总图表组件
import collect from "./components/Collect";
import queryPop from "./components/QueryPop";
import { getRoutineAndEmergencyRate,exportRoutine,exportCenterDetail } from "./api";
export default {
  name: "statistics",
  components: {
    pie,
    bar,
    collect,
    queryPop,
  },
  data() {
    return {
      //表格 start
      tableData: [],
      tcolumns: [
        {
          label: "开始月份",
          prop: "startDate",
          ellipsis: true,
          minWidth: 120,
        },
        {
          label: "结束月份",
          prop: "endDate",
          ellipsis: true,
          minWidth: 120,
        },
        {
          label: "供应商",
          prop: "orgName",
          ellipsis: true,
          minWidth: 120,
        },
        {
          label: "物料组",
          prop: "matklName",
          ellipsis: true,
          minWidth: 120,
        },
        {
          label: "常规订单台数",
          prop: "cgData",
          ellipsis: true,
          minWidth: 140,
          align:'right'
        },
        {
          label: "应急订单台数",
          prop: "yjData",
          ellipsis: true,
          minWidth: 140,
          align:'right'
        },
        {
          label: "常规占比",
          prop: "cgZbData",
          ellipsis: true,
          minWidth: 120,
          align:'right'
        },
        {
          label: "应急占比",
          prop: "yjZbData",
          ellipsis: true,
          minWidth: 120,
          align:'right'
        },

        {
          label: "操作",
          prop: "",
          key: "btnGroup",
          slot: "btnGroup",
          minWidth: 120,
        },
      ],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        sortName: "",
        sortOrder: "",
      },
      pageLoadFlag: false,
      navList: [], //二级导航数据
      //面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: "/",
          name: "",
          title: "常规应急占比分析",
        },
      ],
      isViewMoreFlag: false, //展示更多信息的标志
      chartData: [], //图表数据
      chartItemArr: [],
      startDate: moment(new Date(this.getLastSixMon()[5])),
      endDate: moment(new Date()),
      filterFrom: {
        // startDate:this.getLastSixMon()[5],
        // endDate:this.getLastSixMon()[0],
        // startDate:new Date(this.getLastSixMon()[5]),
        // endDate:new Date(this.getLastSixMon()[0]),
        startDate: this.getLastSixMon()[5]
          .split("-")
          .join(""),
        endDate: this.getLastSixMon()[0]
          .split("-")
          .join(""),
        orgIds: "",
        matklIds: "",
        // orgIds:"209",
        // matklIds:"20160119008"
      },
      noDataType: 0, //0 有数据 1无数据  2空搜不展示数据
      exportRoutineLoading:false,
      exportCenterDetailLoading:false,
      sumDetailMsg:'',//提示
      monthDetailMsg:'',
    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      isFenxiaoOrder: (state) => state.user.userInfo.account.marketModels,
      account: (state) => state.user.userInfo.account.account,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
    }),
  },
  mounted() {
    //头导航数组
    this.navList = navUtil.statisticsList;
    this.getRoutineAndEmergencyRate();

    //提示信息
    const sumDetailMsg = this.$configTotal({
      route: this.$route.fullPath,
      id: "1418000104"
    });
    this.sumDetailMsg = sumDetailMsg ? sumDetailMsg.msgDesc : null;

    const monthDetailMsg = this.$configTotal({
      route: this.$route.fullPath,
      id: "1418000105"
    });
    this.monthDetailMsg = monthDetailMsg ? monthDetailMsg.msgDesc : null;
  },
  methods: {
    //统计
    
    exportRoutineFun(){
      this.exportRoutineLoading = true;
      const params = this.filterFrom;
      exportRoutine(params).then(res => {
        this.$util.blobToJson(res.data).then(content=>{
          if(content&&content.code=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          this.$util.downloadFile(res.data,'常规应急占比统计.xls')
        }).finally(()=>{
          this.exportRoutineLoading = false;
        })
      }).catch(err=>{
        this.exportRoutineLoading = false;
      });
    },

    exportCenterDetailFun(){
      this.exportCenterDetailLoading = true;
      const params = this.filterFrom;

      exportCenterDetail(params).then(res => {
        this.$util.blobToJson(res.data).then(content=>{
          if(content&&content.code=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          this.$util.downloadFile(res.data,'常规应急占比统计月度明细.xls')
        }).finally(()=>{
          this.exportCenterDetailLoading = false;
        })
      }).catch(err=>{
        this.exportCenterDetailLoading = false;
      });
    },
    
    exportItemFun(item){
      this.pageLoadFlag = true;
      const params = {
        startDate:this.filterFrom.startDate,
        endDate:this.filterFrom.endDate,
        orgIds:item.orgId,
        matklIds:item.matklId,
      };
      exportRoutine(params).then(res => {
        this.$util.blobToJson(res.data).then(content=>{
          if(content&&content.code=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          this.$util.downloadFile(res.data,'常规应急占比统计.xls')
        }).finally(()=>{
          this.pageLoadFlag = false;
        })
      }).catch(err=>{
        this.pageLoadFlag = false;
      });
    },
    downloadFile(blob,name) {
      if (
          "download" in document.createElement("a") &&
          navigator.userAgent.indexOf("Edge") == -1
      ) {
        var elink = document.createElement("a");
        elink.download = name;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, name);
      }
    },
    //点击详情
    onDetail(row,idx) {
      if (this.chartItemArr.length == 0) {
        // this.chartItemArr.push(this.chartData[idx+1]);
        for (const item of this.chartData) {
          if (item.orgId + item.matklId == row.orgId + row.matklId) {
            this.chartItemArr.push(item);
            return
          }
        }
      } else {
        //定位
        for (const item of this.chartItemArr) {
          if (item.orgId + item.matklId == row.orgId + row.matklId) {
            //滚动到新生成的月份图表位置
            $("body,html").animate(
              {
                scrollTop: $("#" + row.orgId + row.matklId).offset().top - 80,
              },
              500
            );
            return;
          }
        }
        //添加
        for (const item of this.chartData) {
          if (item.orgId + item.matklId == row.orgId + row.matklId) {
            this.chartItemArr.push(item);
            return
          }
        }
        
      }
    },

    //禁用日期
    disabledStartDate(startValue) {
      const endValue = this.endDate;

      if (!startValue || !endValue) {
        return false;
      }
      return (
        new Date(
          this.$util.dateFmt(new Date(startValue.valueOf()), "yyyy-MM")
        ).valueOf() >
        new Date(
          this.$util.dateFmt(new Date(endValue.valueOf()), "yyyy-MM")
        ).valueOf()
      );
    },
    disabledEndDate(endValue) {

      const startValue = this.startDate;

      if (!endValue || !startValue) {
        return false;
      }
      return (
        new Date(
          this.$util.dateFmt(new Date(startValue.valueOf()), "yyyy-MM")
        ).valueOf() >
        new Date(
          this.$util.dateFmt(new Date(endValue.valueOf()), "yyyy-MM")
        ).valueOf()
      );
    },
    //获取最近半年月份
    getLastSixMon() {
      var data = new Date();
      //获取年
      var year = data.getFullYear();
      //获取月
      var mon = data.getMonth() + 1;
      var arry = new Array();
      for (var i = 0; i < 6; i++) {
        if (mon <= 0) {
          year = year - 1;
          mon = mon + 12;
        }
        if (mon < 10) {
          mon = "0" + mon;
        }
        arry[i] = year + "-" + mon;
        mon = mon - 1;
      }
      return arry;
    },
    async getRoutineAndEmergencyRate() {
      this.pageLoadFlag = true;
      this.chartItemArr = [];
      let params = this.filterFrom;
      try {
        const res = await getRoutineAndEmergencyRate(params);
        if (res) {
          // this.chartData = res.data.data;
          //组装表格数据
          let tableData_ = [];
          // for (const itemL1 of res.data.data) {
          res.data.data.forEach((itemL1, idx) => {
            let obj = {};
            obj["index"] = idx;
            obj["orgId"] = itemL1.orgId;
            obj["matklId"] = itemL1.matklId;
            obj["startDate"] = itemL1.list[0].yearMonth;
            obj["endDate"] = itemL1.list[itemL1.list.length - 1].yearMonth;
            obj["orgName"] = itemL1.orgName;
            obj["matklName"] = itemL1.matklName;

            // collectData.Xdata.push(itemL1.matklName + "\n\n" + itemL1.orgName);
            let cgSumCount = 0,
              yjSumCount = 0;
            for (const itemL2 of itemL1.list) {
              cgSumCount += Number(itemL2.currMonthCgOrderCount);
              yjSumCount += Number(itemL2.currMonthYjOrderCount);
            }
            obj["cgData"] = cgSumCount.toString();
            obj["yjData"] = yjSumCount.toString();
            obj["cgZbData"] =
              Number(cgSumCount + yjSumCount) > 0
                ? ((cgSumCount * 100) / (cgSumCount + yjSumCount)).toFixed(2) +
                  "%"
                : "0.00%";
            obj["yjZbData"] =
              Number(cgSumCount + yjSumCount) > 0
                ? ((yjSumCount * 100) / (cgSumCount + yjSumCount)).toFixed(2) +
                  "%"
                : "0.00%";
                
            tableData_.push(obj);

            //属性中添加常规占比数据 -》 方便之后排序
            itemL1.cgZbData =
              Number(cgSumCount + yjSumCount) > 0
                ? (cgSumCount * 100) / (cgSumCount + yjSumCount)
                : 0;
          });
          
          this.tableData = tableData_.sort((a, b) => {
            return Number(b.cgZbData.slice(0,a.cgZbData.length - 2) - Number(a.cgZbData.slice(0,a.cgZbData.length - 2)));
          });
          this.chartData = res.data.data.sort((a, b) => {
            return Number(b.cgZbData) - Number(a.cgZbData);
          });


          this.pageLoadFlag = false;
          if (this.chartData && this.chartData.length > 0) {
            this.noDataType = 0;
          } else {
            this.noDataType = 1;
          }
        } else {
          this.pageLoadFlag = false;
          this.noDataType = 1;
        }
      } catch (error) {
        console.log(error);
        this.noDataType = 1;
        this.pageLoadFlag = false;
      }
    },
    closeItemFun(idx) {
      this.pageLoadFlag = true;
      setTimeout(() => {
        this.pageLoadFlag = false;
      }, 1000);
      let id = this.chartItemArr[idx].orgId + this.chartItemArr[idx].matklId;
      this.chartItemArr = this.chartItemArr.filter(
        (t) => t.orgId + t.matklId != id
      );
      // this.$set(this.chartData,idx,[])
      // this.chartData[idx].
    },
    //报表说明是否展示更多信息
    viewChangeFun() {
      this.isViewMoreFlag = !this.isViewMoreFlag;
    },
    //筛选日期选择变化
    dateOnStartChange(arg, arg2) {
      this.filterFrom.startDate = arg2.split("-").join("");
      this.getRoutineAndEmergencyRate();
    },
    dateOnEndChange(arg, arg2) {
      this.filterFrom.endDate = arg2.split("-").join("");
      this.getRoutineAndEmergencyRate();
    },
    //筛选回调
    onFilterChange(checkedObj) {
      // if(!checkedObj.orgIds || !checkedObj.matklIds){
      //   this.chartData = [];
      //   this.noDataType = 2;
      //   return
      // }
      //合并入参请求对象
      this.filterFrom = { ...this.filterFrom, ...checkedObj[0] };
      //查询列表
      this.getRoutineAndEmergencyRate();
    },
  },
};
