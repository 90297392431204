/*
 * @Author: your name
 * @Date: 2020-12-25 20:05:52
 * @LastEditTime: 2021-01-26 16:33:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\statistics\api.js
 */
import http from "@/utils/request";


//常规应急占比
export function getRoutineAndEmergencyRate(params) {
    return http({
        url: '/cart/getRoutineAndEmergencyRateDatas.nd',
        method: 'get',
        params
    })
}

//导出
export function exportRoutine(params) {
    return http({
        url: '/cart/exportRoutine.nd',
        method: 'get',
        responseType: 'blob',
        params
    })
}

//导出明细
export function exportCenterDetail(params) {
    return http({
        url: '/cart/exportCenterDetail.nd',
        method: 'get',
        responseType: 'blob',
        params
    })
}
